.slider-wrap {
  max-width: 1180px;
  overflow: hidden;
  height: 156px;
  margin: 32px auto;
  border-radius: 6px;

  .slider-v2 {
    height: inherit;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }

  .ctrl-prev, .ctrl-next {
    top: 50%;
    transform: translateY(-50%);
  }
}
